<template>
  <v-container fill-height grid-list-xl>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="ma-3 pa-5">
          <v-card-title primary-title>
           <h3>{{ nombre}} <v-icon large right>person</v-icon> </h3>
          </v-card-title>
          <v-divider class="mb-5"></v-divider>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xl6 lg6 sm6 md12 xs12>
                <v-text-field
                  outlined
                  label="Nombre"
                  append-icon="person"
                  v-model ="getdatosUsuario.Nomuser"
              ></v-text-field>
              <v-text-field
                outlined
                label="RFC"
                append-icon="tab"
              ></v-text-field>
              </v-flex>
              <v-flex xl6 lg6 sm6 md12 xs12>
                <v-text-field
                  outlined
                  label="Email"
                  append-icon="email"
                  v-model ="getdatosUsuario.Email"
                ></v-text-field>
                <v-text-field
                  outlined
                  label="Teléfono"
                  append-icon="local_phone"
                  v-model="getdatosUsuario.Telefono"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="editar">Actualizar </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      nombre: ''
    }
  },

  created () {
    this.nombre = this.getdatosUsuario.Nomuser
  },

  computed: {
    ...mapGetters('Login', ['getdatosUsuario', 'getClientes'])
  },

  methods: {
    ...mapActions('Login', ['validarUser']),

    editar () {
      this.$http.put('auth/api/v1/usuarios/' + this.getdatosUsuario.idusuariosweb, this.getdatosUsuario).then(response => {
      })
    }

  }

}

</script>
